import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux'
import { AppContainer } from 'react-hot-loader';
import IblPage from "./page/page";

document.body.classList.add("beige");

if ( ! document.getElementById("root") ) {
  var div = document.createElement("div");
  div.id = "root";
  document.body.appendChild(div);
}

if ( ! document.getElementById("tooltip-root") ) {
  var div = document.createElement("div");
  div.id = "tooltip-root";
  document.body.appendChild(div);
}

if ( ! document.getElementById("dialog-root") ) {
  var div = document.createElement("div");
  div.id = "dialog-root";
  document.body.appendChild(div);
}


const render = Component => {
  ReactDOM.render(
    <Component />,
    document.getElementById('root')
  );
}

render(IblPage);

if (module["hot"]) {
  module["hot"].accept('./page/page', () => {
    const NextIblPage = require('./page/page').default;
    render(NextIblPage);
  });
}

