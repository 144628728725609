import { AbstractStatisticsPage } from './AbstractStatisticsPage';

class DayStatisticsPage extends AbstractStatisticsPage 
{
    teamSeries(season, dayIdx) {
        return this.series(season.teamsArray, 0, t => ({name: t.name, score: t.series[dayIdx]}) );
    }
    
    teamGames(season, dayIdx) {
        return this.series(season.teamsArray, 0,  t => ({name: t.name, score: t.bestGamesDay[dayIdx]}) );
    }

    ladyGames(season, dayIdx) {
        return this.series(season.ladies, 10, p => ({name: p.name, team: p.team, score: p.bestGamesDay[dayIdx]}) );
    }
    
    gentlemenGames(season, dayIdx) {
        return this.series(season.gentlemen, 10, p => ({name: p.name, team: p.team, score: p.bestGamesDay[dayIdx]}) );
    }

    ladySeries(season, dayIdx) {
        return this.series(season.ladies, 10, p => ({name: p.name, team: p.team, score: p.series[dayIdx]}) );
    }

    gentlemenSeries(season, dayIdx) {
        return this.series(season.gentlemen, 10, p => ({name: p.name, team: p.team, score: p.series[dayIdx]}) );
    }

    render() {
        return super.render("Tagesstatistik: ");
    }
}

export { DayStatisticsPage };
