import React from 'react';

import { oValues, Util } from '../../shared/util';

import '../scss/page/TeamRankingPage.scss';

class PlayerRankingPage extends React.Component 
{
    renderPlayerRows(season, dayIdx) {
        let players = oValues(season.teams).reduce( (a, t) => a.concat(oValues(t.players)), []);

        let minGames = 1;

        players.sort( (p1, p2) => p2.avgs[dayIdx] - p1.avgs[dayIdx] );

        let pos = 0;
        let lastAvg = -1; 
        let playerRows = players.map( p => {
            if (p.gameCounts[dayIdx] < minGames )
                return null;
            const avg = p.avgs[dayIdx];
            let posLabel = null;
            if ( avg != lastAvg ) {
                posLabel = ++pos;
                lastAvg = avg;
            }

            let bestGameSeason = p.bestGamesSeason[dayIdx];
            let bestGame = bestGameSeason ? bestGameSeason.score : 0;

            var hdcIdx = dayIdx+1;
            if ( hdcIdx > season.days.length )
                hdcIdx--;
            var hdc = p.handicaps[dayIdx+1];

            console.log("p.avgs[dayIdx]: " + dayIdx);
            console.log("p.avgs[dayIdx]: " + p.avgs[dayIdx]);

            return <tr key={p.team.name + p.name}>
                        <td className="num">{posLabel}</td>
                        <td className="gender"><i className={"icon-" + (p.gender == "male" ? "mars" : "venus")}/></td>
                        <td className="nameCell">
                            <span className="name">
                                {p.name}
                            </span>
                            { p.guest ? <span className="team"> (Gast)</span> : null }
                            <br/>
                            <span className="team">
                                {p.team.name}
                            </span>
                        </td>
                        <td className="num">{Util.toFixed(p.avgs[dayIdx], 1)}</td>
                        <td className="smallHdcCell num">
                            <span className="smallHdc">
                                {hdc == 0 ? <span className="gold">0</span> : <span>{hdc}</span>}
                            </span>
                            <br/>
                            <span className="smallAvgLastN">
                                {Util.toFixed(p.lastNavgs[dayIdx], 1)}
                            </span>
                        </td>
                        <td className="num">{bestGame}</td>
                        <td className="num">{p.gameCounts[dayIdx]}</td>
                   </tr>
        });

        return playerRows;
    }

    renderTable(season, dayIdx, small) {
        return <div className="tableContainer">
                    <table>
                        <thead>
                            <tr>
                                <th className="num">#</th>
                                <th className="gender"><i className="icon-transgender-alt"/></th>
                                <th className="smallHdcCell">
                                    <span className="smallHdc">Spieler</span>
                                    <br/>
                                    <span className="smallAvgLastN">Team</span>
                                </th>
                                <th className="num">Schnitt</th>
                                <th className="smallHdcCell num">
                                    <span className="smallHdc">HDC</span>
                                    <br/>
                                    <span className="smallAvgLastN">⌀ last {season.hdcLastGames}</span>
                                </th>
                                <th className="num">Best</th>
                                <th className="num">{small ? "#" : "Spiele"}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderPlayerRows(season, dayIdx)}
                        </tbody>
                    </table>
               </div>
    }

    render() {
        const {season, dayIdx} = this.props;
        const day = season.days[dayIdx];

        const small = window.innerWidth < 540;
        
        const content = day.done ? this.renderTable(season, dayIdx, small) 
                                 : <h5>... steht noch nicht fest.<br/><br/>Vielleicht liegt der Spieltag in der Zukunft oder die Menschen haben schlichtweg vergessen, wie man die Ergebnisse einträgt. <br/><br/>Denk mal drüber nach!</h5>;

        return (
            <div className="TeamRankingPage PlayerRankingPage">
                <h4>Players Ranking nach {Util.dayLabel(day)}</h4>
                {content}
            </div>
        );
    }

}

export { PlayerRankingPage };
