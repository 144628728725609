import React from 'react';

import { StatisticTable } from './StatisticTable';
import { Util } from '../../shared/util';

import '../scss/page/TeamRankingPage.scss';
import '../scss/page/DayStatisticsPage.scss';

class AbstractStatisticsPage extends React.Component 
{
    series(array, max, mapper) {
        let list = array.map(mapper);
        list = list.filter(v => v.score > 0);
        list.sort( (p1, p2) => p2.score - p1.score );
        if ( max && list.length > max )
            list = list.slice(0, max);
        return list;
    }
    
    teamGames(season, dayIdx) {}
    teamSeries(season, dayIdx) {}
    ladyGames(season, dayIdx) {}
    gentlemenGames(season, dayIdx) {}
    ladySeries(season, dayIdx) {}
    gentlemenSeries(season, dayIdx) {}

    renderThemTables(s, d) {
        return <>
                   <StatisticTable title="Team: Bestes Spiel"   nameTitle="Team"      values={this.teamGames(s, d)}/>
                   <StatisticTable title="Team: Beste Serie"    nameTitle="Team"      values={this.teamSeries(s, d)}/>
                   <StatisticTable title="Damen: Bestes Spiel"  nameTitle="Lady"      values={this.ladyGames(s, d)}/>
                   <StatisticTable title="Herren: Bestes Spiel" nameTitle="Gentleman" values={this.gentlemenGames(s, d)}/>
                   <StatisticTable title="Damen: Beste Serie"   nameTitle="Lady"      values={this.ladySeries(s, d)}/>
                   <StatisticTable title="Herren: Beste Serie"  nameTitle="Gentleman" values={this.gentlemenSeries(s, d)}/>
               </>
    }

    render(pageLabel) {
        const {season, dayIdx} = this.props;
        const day = season.days[dayIdx];

        console.log("SEASON: ", season);

        return (
            <div className="TeamRankingPage DayStatisticsPage">
                <h4>{pageLabel} {Util.dayLabel(day, true)}</h4>
                {day.done ? this.renderThemTables(season, dayIdx) 
                          : <h5>... steht noch nicht fest :-|<br/><br/>Vielleicht später...</h5>}
            </div>
        );
    }
}

export { AbstractStatisticsPage };
