
const MONTH_NAMES = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember" ]
const MONTH_NAMES_SHORT = ["Jan", "Feb", "März", "April", "Mai", "Juni", "Juli", "Aug", "Sept", "Okt", "Nov", "Dez" ]

// TODO move stuff only used in admin to admin
class Util {

    static playerHasGames(season, teamName, playerName) {
        return season.days.find( d => d.rounds.find( r => r.games.find( 
                g => (g.teamGame1.team == teamName && g.teamGame1.players.includes(playerName))
                  || (g.teamGame2.team == teamName && g.teamGame2.players.includes(playerName))
         ) ) );
    }

    static teamHasGames(season, teamName) {
        return season.days.find( d => d.rounds.find( r => r.games.find( 
                g => (g.teamGame1.team == teamName || g.teamGame2.team == teamName)
         ) ) );
    }

    static playersLastGames(season, teamName, playerName, dayIdx ) {
        let prevGames = (season.teams[teamName].players[playerName].prevGames || []).slice();

        dayIdx = dayIdx < season.days.length ? dayIdx : season.days.length-1; 

        for ( let i = 0; i <= dayIdx; i++ ) {
            season.days[i].rounds.forEach( r =>
                r.games.forEach( g => {
                    if ( g.teamGame1.team == teamName ) {
                        let idx = g.teamGame1.players.indexOf(playerName);
                        if ( idx > -1 )
                            prevGames.push(g.teamGame1.results[idx]);  
                    }
                    if ( g.teamGame2.team == teamName ) {
                        let idx = g.teamGame2.players.indexOf(playerName);
                        if ( idx > -1 )
                            prevGames.push(g.teamGame2.results[idx]);
                    }
                })
            )
        }

        if ( prevGames.length > season.hdcLastGames )
            prevGames = prevGames.slice(-season.hdcLastGames);

        return prevGames;
    }

    static calculateHandicap(season, teamName, playerName, dayIdx) {
        let prevGames = this.playersLastGames(season, teamName, playerName, dayIdx);
        if ( prevGames && prevGames.length ) {
            let average = prevGames.reduce( (a, h) => a+h ) / prevGames.length;
            let hdc = Math.round((season.hdcBase - average) * season.hdcFactor);
            if ( hdc < 0 )
                hdc = 0;
            if ( hdc > season.hdcMax )
                hdc = season.hdcMax;
            return hdc;
        }
        return null;
    }

    static dateLong(date, shortMonth) {
        return date.getDate() + ". " + (shortMonth ? MONTH_NAMES_SHORT : MONTH_NAMES)[date.getMonth()] + " " + date.getFullYear()
    }

    static dateNum(date) {
        return date.getDate() + "." + (date.getMonth()+1) + " " + date.getFullYear()
    }

    static dayLabel(day, shortMonth) {
	    const date = day.date;
	    return day.title + " - " + this.dateLong(date, shortMonth);
    }

    static dayLabelShort(day) {
	    return day.title + " - " + day.date.getDate() + ". " + MONTH_NAMES_SHORT[day.date.getMonth()];
    }

    static replaceAll(str, rex, repl) {
        return str.replace( new RegExp(rex, 'g'), repl );
    }

    static toFixed(num, places) {
        return num.toFixed(places).toString().replace(".", ",");
    }

}

// @ts-ignore stfu you stupid f*ck
const oValues = o => Object.values(o);

export {Util, oValues};
