import { AbstractStatisticsPage } from './AbstractStatisticsPage';

class SeasonStatisticsPage extends AbstractStatisticsPage
{
    teamGames(season, dayIdx) {
        return this.series(season.teamsArray, 0,  t => ({name: t.name, ...t.bestGamesSeason[dayIdx]}) );
    }

    teamSeries(season, dayIdx) {
        return this.series(season.teamsArray, 0, t => ({name: t.name, ...t.bestSeries[dayIdx]}) );
    }

    ladyGames(season, dayIdx) {
        return this.series(season.ladies, 10, p => ({name: p.name, ...p.bestGamesSeason[dayIdx]}) );
    }
    
    gentlemenGames(season, dayIdx) {
        return this.series(season.gentlemen, 10, p => ({name: p.name, ...p.bestGamesSeason[dayIdx]}) );
    }

    ladySeries(season, dayIdx) {
        return this.series(season.ladies, 10, p => ({name: p.name, ...p.bestSeries[dayIdx]}) );
    }

    gentlemenSeries(season, dayIdx) {
        return this.series(season.gentlemen, 10, p => ({name: p.name, ...p.bestSeries[dayIdx]}) );
    }

    render() {
        return super.render("Saison-Statistik nach ")
    }
}

export { SeasonStatisticsPage };
