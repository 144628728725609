import React from 'react';


import '../scss/page/StartPage.scss';

class StartPage extends React.Component 
{
    render() {
        return (
            <div className="StartPage">
	            <div className="box">
					<h1>Independent Bowlers Leage</h1>
					<div className="boxContent">
						<div style={{width: "100%", textAlign: "center"}}>
							<img src="/images/iblogo.jpg" style={{maxWidth: "80%", marginLeft: "auto", marginRight: "auto", borderRadius: "50%"}}/>
						</div>
						<p>
							Die <b><i>Independent Bowlers Leage</i></b> ist eine Freizeit-Bowlingliga in Berlin, bei der Spaß und gute Musik mindestens genau 
							wichtig sind, wie die sportliche Performance. Unsere Handicap-Regelung sorgt dafür, dass auch weniger erfahrene Spieler*innen
							ordentlich Punkte machen können. Wir treffen uns für gewöhnlich von Oktober bis Mai zwei mal im Monat in der 
						</p>
						<p style={{textAlign: "center"}}>
							<a href="https://berolina-bowling.de">Berolina Bowling Lounge</a>  
						</p>
					</div>
				</div>
				<div className="box">
					<h2>Saison 2020/21</h2>
					<div className="boxContent">
						<p>
							Die Saison startet am 27. Oktober. Maske nicht vergessen!
						</p>
					</div>
				</div>
            </div>
        );
    }
}

export { StartPage };
