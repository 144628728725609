import React from 'react';
import '../scss/components/Forms.scss'

const ActionListener = React.createContext( { 
    onAction: (action) => { console.log("default ActionListener called. Action: " + action) } 
});


const FocusListener = React.createContext( { 
    onFocus: (name) => { console.log("default FocusListener.onFocus called. Input name: " + name) },
    onUnfocus: (name) => { console.log("default FocusListener.onUnfocus called. Input name: " + name) } 
});


const ChangeListener = React.createContext( {
    onInit:   () => { console.error("default ChangeListener.onInit called") },
    onChange: (n, v) => { console.error(`default ChangeListener.onChange called! name='${n} value=`, v) } 
});

class InputForm extends React.PureComponent
{
    constructor(props) {
        super(props);
        this.onAction = this.onAction.bind(this);
        this.onChange = this.onChange.bind(this);
        this.data = {};
    }

    render() {
        return (
            <ActionListener.Provider value={{onAction: this.onAction}}>
                <ChangeListener.Provider value={{onChange: this.onChange, onInit: this.onChange}}>
                    <form className="InputForm">
                        {this.props.children}
                    </form>
                </ChangeListener.Provider>
            </ActionListener.Provider>
        );
    }

    onAction(action) {
        if ( this.props.onFormAction )
            this.props.onFormAction(action, this.data);
    }

    onChange(name, value) {
        this.data[name] = value;
        if ( this.props.onChange )
            this.props.onChange(name, value);
    }
}

class Input extends React.PureComponent
{
    constructor(props) {
        super(props);
    }

    renderTextArea(name, value, changeListener, focusListener, hidden) {
        return <textarea name={name}
                         onFocus={() => focusListener.onFocus(name)}
                         onBlur ={() => focusListener.onUnfocus(name)}
                         onChange={(e) => changeListener.onChange(name, e.target.value)}>
               </textarea>;
    }

    renderInput(name, type, value, changeListener, focusListener, hidden) {
        const valProp = type === "checkbox" || type === "radio" ? "checked" : "value";

        return <input name={name}
                      type={type}
                      value={value}
                      checked={value}
                      hidden={hidden}
                      onFocus={() => focusListener.onFocus(name)}
                      onBlur ={() => focusListener.onUnfocus(name)}
                      onChange={(e) => changeListener.onChange(name, e.target[valProp])}/>
    }

    renderSelect(name, value, changeListener, focusListener, hidden) {
        return <select name={name}
                       hidden={hidden}
                       value={value}
                       onFocus={() => focusListener.onFocus(name)}
                       onBlur ={() => focusListener.onUnfocus(name)}
                       onChange={(e) => changeListener.onChange(name, e.target.value)}
                >{this.props.children}</select>;
    }

    render() {
        const {type, name, value, hidden} = this.props;

        return <ChangeListener.Consumer>{ changeListener =>
                  <FocusListener.Consumer>{ focusListener =>
                    {return type == "textarea" ?
                        this.renderTextArea(name, value, changeListener, focusListener, hidden)
                      :     type == "select" ?
                        this.renderSelect(name, value, changeListener, focusListener, hidden)
                      :
                        this.renderInput(name, type, value, changeListener, focusListener, hidden)
                    }}</FocusListener.Consumer>
               }</ChangeListener.Consumer>;
    }
}

class Select extends React.PureComponent
{
    constructor(props) {
        super(props);
    }

    render() {
        const { name, value, hidden } = this.props;

        // let hiddenAttr = hidden ? "hidden" : "";

        return <ChangeListener.Consumer>{ changeListener =>
                  <FocusListener.Consumer>{ focusListener =>
                        <select name={name}
                                hidden={hidden}
                                value={value}
                                onFocus={() => focusListener.onFocus(name)}
                                onBlur ={() => focusListener.onUnfocus(name)}
                                onChange={(e) => changeListener.onChange(name, e.target.value)}
                        >
                            {this.props.children}
                        </select>
                    }</FocusListener.Consumer>
               }</ChangeListener.Consumer>;

    }
}


class Radio extends React.PureComponent
{
    constructor(props) {
        super(props);
    }

    render() {
        const { name, value, hidden, checked } = this.props;

        return <ChangeListener.Consumer>{ changeListener =>
                    <FocusListener.Consumer>{ focusListener =>
                        <label>
                            <input type="radio" 
                                   name={name}
                                   hidden={hidden}
                                   value={value}
                                   checked={checked}
                                   onFocus={() => focusListener.onFocus(name)}
                                   onBlur ={() => focusListener.onUnfocus(name)}
                                   onChange={(e) => changeListener.onChange(name, e.target.value)}
                            />
                            {this.props.children}
                        </label>
                    }</FocusListener.Consumer>
               }</ChangeListener.Consumer>;

    }
}



class InputRow extends React.Component 
{
    constructor(props) {
        super(props);
        this.state = { focused: false };
        this.onFocus = this.onFocus.bind(this);
        this.onFocusLost = this.onFocusLost.bind(this);
    }

    render() {
        let { name, type, value, children, hidden } = this.props;

        let inputChildren = null;
        if ( type == "select" ) {
            inputChildren = children;
            children = null; // used inside of Input.renderSelect()
        }

        // TODO development mode
        // @ts-ignore F...U...
        if ( name && type && children )
            console.error("using type & name and children together!");

        if ( hidden )
            return null;
            
        return (
            <label className={"InputRow" + (this.state.focused ? " focused" : "")}>
                <div className="InputRowLabel" >
                    <span>{this.props.label}</span>
                </div>
                <div className="InputRowInputContainer">
                    <FocusListener.Provider value={{onFocus: this.onFocus, onUnfocus: this.onFocusLost}}>
                        {name && type && 
                            <Input name={name} type={type} value={value} hidden={hidden} children={inputChildren}/>}
                        {children}
                    </FocusListener.Provider>
                </div> 
            </label>
        );
    }

    onFocus() {
        this.setState({focused: true});
    }
    
    onFocusLost() {
        this.setState({focused: false});
    }

} 



function ButtonRow(props) {
    return <div className="ButtonRow">{props.children}</div>;
}

function ButtonRowSpacer(props) {
    return <div className="ButtonRowSpacer">{props.children}</div>;
}


class Button extends React.Component {
    render() {
        const { text, icon, action, disabled } = this.props;

        let iconElm = icon ? <i className={icon + " ButtonIcon"}></i> : null;

        return (
            <ActionListener.Consumer> 
                { listener => <button type="button" className="Button2" disabled={disabled} 
                                      onClick={() => listener.onAction(action)}>
                                  {iconElm} {text}
                              </button>
                }
            </ActionListener.Consumer>
        );
    }
}

class MultiButton extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <div className="MultiButton">
                   {this.props.children}
               </div>
    }
}


export { InputForm, InputRow, Input, Select, Radio, 
         ButtonRow, ButtonRowSpacer, Button, MultiButton,
         ActionListener
       };