import { oValues } from "../../shared/util";


class Season 
{
	constructor(jsonData) {
		this.name = jsonData.name;
		this.roundsPerDay = jsonData.roundsPerDay;
		this.playersPerGame = jsonData.playersPerGame;
		this.hdcBase = jsonData.hdcBase;		
		this.hdcLastGames = jsonData.hdcLastGames;		
		this.hdcFactor = jsonData.hdcFactor;
		this.firstLane = jsonData.firstLane;

		this.blindPlayers = [];
		let blindHdcps = Array(jsonData.days.length).fill(0);
		for ( let i = 0; i < this.playersPerGame; i++ ) {
			this.blindPlayers[i] = new Player({ name: "Blind " + (i+1), handicaps: blindHdcps });
			this.blindPlayers[i].isBlind = true;
			this.blindPlayers[i].blindScore = jsonData.blindScores[i];
		}
		
		this.doneDays = 0;
		while (this.doneDays < jsonData.days.length && jsonData.days[this.doneDays].done)
			this.doneDays++;
		
		let zeroes = new Array(this.doneDays);
		let nulls = new Array(this.doneDays);
		zeroes.fill(0);
		nulls.fill(null);

		let teams = this.teams = {};
		this.teamsArray = [];
		this.allPlayers = [];
		this.ladies = [];
		this.gentlemen = [];
		oValues(jsonData.teams).forEach( t => {
			let tm = new Team(this, t, zeroes, nulls);
			teams[tm.name] = tm;
			this.teamsArray.push(tm);
		});
		this.days = jsonData.days.map( (d, i) => new PlayDay(this, i, d) );

		let t0 = new Date().getTime();		
		this.calculateStats();

		// console.log("Season after calculations (" + (new Date().getTime() - t0) + "ms): ", this);
	}

	calculateStats() {
		for (let i = 0; i < this.doneDays; i++ ) {
			let day = this.days[i];

			if ( i > 0 ) {
				this.teamsArray.forEach(t => {
					t.seriesSum[i] = t.seriesSum[i-1];
					t.points[i] = t.points[i-1];
					t.bestGamesSeason[i] = t.bestGamesSeason[i-1];
					t.bestSeries[i] = t.bestSeries[i-1];
				});
				
				this.allPlayers.forEach( p => {
					p.bestGamesSeason[i] = p.bestGamesSeason[i-1];
					p.bestSeries[i] = p.bestSeries[i-1];
					p.gameCounts[i] = p.gameCounts[i-1];
				});
			}

			day.rounds.forEach( (r, ri) => r.games.forEach( g => {
				let tg1 = g.teamGame1, tg2 = g.teamGame2;
				let t1 = tg1.team, t2 = tg2.team;
				let result1 = tg1.result, result2 = tg2.result;

				this.processTeamGame(day, i, ri, t1, result1, tg1);
				this.processTeamGame(day, i, ri, t2, result2, tg2);

				if ( ! tg1.blind && result1 > result2 )
					t1.points[i] += 3;
				else if ( ! tg2.blind && result2 > result1 )
					t2.points[i] += 3;
				else if ( ! tg1.blind && ! tg2.blind ) {
					t1.points[i] += 1;
					t2.points[i] += 1;
				}
			}));

//		t1		t2		
//           <



			this.teamsArray.forEach(t => {
				if ( ! t.bestSeries[i] || t.series[i] > t.bestSeries[i].score )
					t.bestSeries[i] = {score: t.series[i], day: day};				
			});

			this.allPlayers.forEach( p => {
				if ( p.series[i] && (! p.bestSeries[i] || p.series[i] > p.bestSeries[i].score) )
					p.bestSeries[i] = {score: p.series[i], day: day, team: p.team};	

				if ( p._games.length )
					p.avgs[i] = p._games.reduce( (sum, score) => sum += score ) / p._games.length;

				let gms = p._games.length >= this.hdcLastGames ? p._games : p.prevGames.concat(p._games);
				if ( gms.length ) {
					gms = gms.slice(-this.hdcLastGames);
					p.lastNavgs[i] = gms.reduce( (sum, score) => sum += score ) / gms.length;
				}
			});
		}
	}

	processTeamGame(day, dayIdx, round, team, result, teamGame) {
		if ( result > team.bestGamesDay[dayIdx] )
			team.bestGamesDay[dayIdx] = result;
		if ( ! team.bestGamesSeason[dayIdx] || result > team.bestGamesSeason[dayIdx].score )
			team.bestGamesSeason[dayIdx] = {score: result, hdc: result - teamGame.rawResult, day, round};
		team.series[dayIdx] += result;
		team.seriesSum[dayIdx] += result;

		teamGame.players.forEach( (p, pi) => {
			if ( p.isBlind )
				return;
			let result = teamGame.results[pi];
			p.gameCounts[dayIdx]++;
			p._games.push(result);
			if ( result > p.bestGamesDay[dayIdx] )
				p.bestGamesDay[dayIdx] = result;
			if ( ! p.bestGamesSeason[dayIdx] || result > p.bestGamesSeason[dayIdx].score )
				p.bestGamesSeason[dayIdx] = {score: result, day, round, team};						
			p.series[dayIdx] += result + p.handicaps[dayIdx];
		});
	}
}


class Team 
{
	constructor(season, jsonData, zeroes, nulls) {
		this.season			 = season;
		this.name			 = jsonData.name;
		this.players		 = {};
		this.points          = zeroes.slice();
		this.bestGamesDay    = zeroes.slice();
		this.bestGamesSeason = nulls.slice();
		this.series          = zeroes.slice();
		this.seriesSum		 = zeroes.slice();
		this.bestSeries      = nulls.slice();

		oValues(jsonData.players).forEach( jd => {
			let p = new Player(jd, this, season, zeroes, nulls);
			this.players[p.name] = p;
			return p;
		});
	}
}


class Player 
{
	constructor(jsonData, team, season, zeroes, nulls) {
		this.name = jsonData.name;
		this.team = team;
		this.handicaps = jsonData.handicaps;
		this.prevGames = jsonData.prevGames;
		this.gender = jsonData.gender;
		this.guest = jsonData.guest;
		if ( team && season ) {
			season.allPlayers.push(this);
			if ( this.gender == "female" )
				season.ladies.push(this);
			else
				season.gentlemen.push(this);
			this.gameCounts      = zeroes.slice();
			this.bestGamesDay    = zeroes.slice();
			this.bestGamesSeason = nulls.slice();
			this.avgs            = zeroes.slice();
			this.lastNavgs       = zeroes.slice();
			this.series      	 = zeroes.slice();
			this.bestSeries  	 = nulls.slice();
			this._games          = []; 
		}		
	}
}


class PlayDay 
{
	constructor(season, dayIdx, jsonData) {
		// this.date = new Date(jsonData.date);
		this.title = jsonData.title;
		this.abbreviation = jsonData.abbreviation;
		this.date = new Date(jsonData.date);
		this.rounds = jsonData.rounds.map( (r, i) => new Round(season, dayIdx, i, r));
		this.done = jsonData.done;
		this.dayIdx = dayIdx;
		// this.done = !! jsonData.rounds.find( r =>
		// 	r.games.find( g => 
		// 		g.teamGame1.players.length || g.teamGame2.players.length
		// ));
	}
}


// TODO reference day instead of using dayidx
class Round 
{
	constructor(season, dayIdx, roundIdx, jsonData) {
		this.roundIdx = roundIdx;
		this.games = jsonData.games.map( (g, i) => new Game(season, dayIdx, roundIdx, i, g));
	}
}


class Game 
{
	constructor(season, dayIdx, roundIdx, gameIdx, jsonData) {
		this.teamGame1 = new TeamGame(season, dayIdx, jsonData.teamGame1);
		this.teamGame2 = new TeamGame(season, dayIdx, jsonData.teamGame2);
		this.teamGames = [this.teamGame1, this.teamGame2];
		this.dayIdx    = dayIdx;
		this.roundIdx  = roundIdx;
		this.gameIdx   = gameIdx;
		this.season    = season;
	}
}


class TeamGame
{
	constructor(season, dayIdx, jsonData) {
		this.season = season;
		this.dayIdx = dayIdx;
		this.lane = jsonData.lane;
		this.team = season.teams[jsonData.team];
		this.players = jsonData.players.map( p => this.team.players[p] );
		this.results = jsonData.results;

        let blind = 0;
        while ( this.players.length < this.season.playersPerGame ) {
            this.players.push(this.season.blindPlayers[blind]);
            this.results.push(this.season.blindPlayers[blind].blindScore);
            blind++;
		}
		
		this.blind = blind === this.season.playersPerGame;

		this.result = 0;
		this.rawResult = 0;
		for ( let i = 0; i < this.players.length; i++ ) {
			this.result += this.results[i] + this.players[i].handicaps[this.dayIdx];
			this.rawResult += this.results[i];
		}
	}
}

export { Season, Team, Player, PlayDay, Round, Game, TeamGame };
