import React from 'react';

import { Util } from '../../shared/util';

import '../scss/page/TeamRankingPage.scss';

class TeamRankingPage extends React.Component 
{
    renderTable(season, dayIdx) {
        let teams = season.teamsArray.slice();

        teams.sort( (t1, t2) => {
            let d = t2.points[dayIdx] - t1.points[dayIdx];
            if ( d === 0 )
                return t2.seriesSum[dayIdx] - t1.seriesSum[dayIdx];
            return d;
        } );

        let pos = 0;
        let lastPoints = -1, lastScore = -1; 
        let teamRows = teams.map( t => {
            const p = t.points[dayIdx];
            const s = t.seriesSum[dayIdx];
            let posLabel = null;
            if ( p != lastPoints || s != lastScore ) {
                posLabel = ++pos;
                lastPoints = p;
                lastScore = s;
            }
            return <tr>
                        <td className="num">{posLabel}</td>
                        <td><span className="name">{t.name}</span></td>
                        <td className="num">{p}</td>
                        <td className="num">{s}</td>
                    </tr>
        });

        return <div className="tableContainer">
                   <table>
                       <thead>
                           <tr>
                               <td className="num">#</td>
                               <td>Team</td>
                               <td className="num">Punkte</td>
                               <td className="num">Score</td>
                           </tr>
                       </thead>
                       <tbody>
                           {teamRows}
                       </tbody>
                   </table>
               </div>
    }


    render() {
        const {season, dayIdx} = this.props;
        const day = season.days[dayIdx];

        let content = day.done ? this.renderTable(season, dayIdx) 
                               : <h5>... steht noch nicht fest.<br/><br/>Das ist okay. Mach dir keine Sorgen.<br/><br/>Vielleicht möchtest du einen anderen Spieltag dort oben rechts im Menü auswählen.</h5>

        return (
            <div className="TeamRankingPage">
                <h4>Team Ranking nach {Util.dayLabel(day, true)}</h4>
                {content}
            </div>
        );
    }

}

export { TeamRankingPage };
