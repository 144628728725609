import React from 'react';

class TeamsPage extends React.Component 
{
    render() {
        return (
            <div style={{marginLeft: "auto", marginRight: "auto", marginTop: 50, maxWidth: 800, padding: 20}}>
                <div style={{color: "black", backgroundColor: "white", display: "flex"}}>
                    <div style={{padding: 8}}>
                        <img src="images/favicon.png" width="50px"></img>
                    </div>
                    <div style={{padding: "8px 8px 8px 0px"}}>
                        <div>
                            <b>Bowling B. Ball</b><span style={{color: "#666"}}>&nbsp;@realBBall</span>
                        </div>
                        <div style={{marginTop: 8, marginBottom: 8}}>
                            At I-B-L we have the best teams. They're great. Tremendous. Believe me. It's true. 
                            Other leagues also say they have great teams. They dont! They're FAKE leagues.
                            So sad!
                        </div>
                        <div style={{color: "#666"}}>
                            <i className="icon-comment"></i> 123 Tds. &nbsp;&nbsp;
                            <i className="icon-retweet"></i> 98 Mio. &nbsp;&nbsp;
                            <i className="icon-heart"></i> 0 
                        </div>
                    </div>
                    
                </div>
            </div>
        );
    }
}

export { TeamsPage };
