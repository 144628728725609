import React from 'react';

import { ActionListener } from '../components/Forms';

import '../scss/page/Menu.scss';

let menuItemHeight = 0;

/**
 * children: pass your this.props.children
 * props:    props to add to each childres props
 */
function applyToChildren(children, props, componentType) {
    return React.Children.map(children, (child) => {
        // @ts-ignore
        if ( ! componentType || (child.type && child.type.name === componenetType) )
            // @ts-ignore
            return React.cloneElement(child, props);
        else
            return child;
    });
}

class MenuItem extends React.Component {
    
    constructor(props) {
        super(props);
        if ( props.children ) {
            this.numChildren = props.children.length;
            this.menuItem         = React.createRef();
            this.subMenuContainer = React.createRef();
        }

        this.enter = this.enter.bind(this);
        this.leave = this.leave.bind(this);
        this.click = this.click.bind(this);

        this.state = {expanded: false}
    }

    render() {
        let subMenuContainerStyle = {};  
        if ( this.state.expanded )
            subMenuContainerStyle.height = (menuItemHeight * this.numChildren);

        return this.props.topLevel ? 
            <ActionListener.Consumer>{ listener => 
                <div className="menuMenuItem" 
                        onMouseOver={this.enter} 
                        onMouseLeave={this.leave} 
                        onClick={this.click}>
                    <div className="menuItem" key="item" ref={this.menuItem}
                            onClick={() => { listener.onAction(this.props.action); }}>
                        {this.props.label}
                    </div>
                    <div className="subMenuContainer" key="sub" ref={this.subMenuContainer} style={subMenuContainerStyle} >
                        <ActionListener.Provider value={{onAction: (a) => this.onSubItemAction(a,listener)}}>
                            {this.props.children}
                        </ActionListener.Provider>
                    </div>
                </div>
            }</ActionListener.Consumer>
        :
            <ActionListener.Consumer>{ listener => 
                <div className="menuItem" data-action={this.props.action} 
                        onClick={() => { listener.onAction(this.props.action); this.leave(); }}>
                    {this.props.label}
                </div>
            }</ActionListener.Consumer>
    }

    onSubItemAction(action, listener) {
        this.leave();
        listener.onAction(action);
    }

    enter() {
        // TODO change state ("opened") and apply style in render() 
        // TODO also on click and touch
        if ( ! menuItemHeight )
            menuItemHeight = this.menuItem.current.clientHeight;
        this.setState({...this.state, expanded: true});
    }

    leave() {
        // if ( this.subMenuContainer )
        //     this.subMenuContainer.current.style.height = "0px";
        this.setState({...this.state, expanded: false});
    }

    click() {
        if ( ! menuItemHeight )
            menuItemHeight = this.menuItem.current.clientHeight;
        // this.setState({...this.state, expanded: ! this.state.expanded});
    }
}

class Menu extends React.Component 
{
    render() {
        const children = applyToChildren(this.props.children, {topLevel: true});
        return (
            <div className="menu">
                {children}
            </div>
        );
    }
}

export { Menu, MenuItem };
