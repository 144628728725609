import React from 'react';
import { ajax, GET } from '../admin/ajax';
import { Season } from './Data';
import { ActionListener } from '../components/Forms';
import { MenuItem, Menu } from './menu';
import { GamesPage } from './GamesPage';
import { StartPage } from './StartPage';
import { TeamRankingPage } from './TeamRankingPage';
import { PlayerRankingPage } from './PlayerRankingPage';
import { DayStatisticsPage } from './DayStatisticsPage';
import { SeasonStatisticsPage } from './SeasonStatisticsPage';
import { TeamsPage } from './TeamsPage';
import { Util } from '../../shared/util';

const PAGE_TYPE_START = "start"; 
const PAGE_TYPE_DAY_GAMES = "games";
const PAGE_TYPE_TEAM_RANKING = "teamRanking";
const PAGE_TYPE_PLAYER_RANKING = "playerRanking";
const PAGE_TYPE_DAY_STATS = "dayStats";
const PAGE_TYPE_SEASON_STATS = "seasonStats";
const PAGE_TYPE_TEAMS = "teams";

const SEASON_NAME_TODO = "2021/22";

const localDateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

function dayMenuLabel(i, day)
{
    return (
        <div className="dayMenuLabel">
            Tag <span className="dayI">{"\u00a0" + (i < 10 ? "\u00a0\u00a0" +i : i)}: {"\u00a0\u00a0"} </span>{day.date.toLocaleDateString('de-DE', localDateOptions)}
        </div>
    );
}

function menuLabel(label, test, val) {
    if ( test === val )
        return <span><i className="icon-caret-right"/> {label} <i className="icon-caret-left"/></span>;
    return label;
}

function IblMenu(props) {

    // var seasonItems = Object.values(props.seasons).map( (s, i) =>
    //     <MenuItem key={i} label={s.name} action={{name: "openSeason", season: s}}/>
    // );

    let { season, dayIdx, pageType } = props.page;

    var dayItems = season.days.map( (d, i) => 
        <MenuItem key={i} label={menuLabel(Util.dayLabelShort(d), d.dayIdx, dayIdx)} action={{name: "showPage", day: i}}/>
    );

    return (
        <Menu>
            {/* <MenuItem label="Hallo">
                <MenuItem key="1" label="Namatze नमस्ते"/>
                <MenuItem key="2" label="Drei"/>
            </MenuItem> */}
            <MenuItem label={"Wat willste?"}>
                <MenuItem label={menuLabel("Startseite", pageType, PAGE_TYPE_START)} action={{name: "showPage", pageType: PAGE_TYPE_START}}/>
                <MenuItem label={menuLabel("Tabelle", pageType, PAGE_TYPE_TEAM_RANKING)} action={{name: "showPage", pageType: PAGE_TYPE_TEAM_RANKING}}/>
                <MenuItem label={menuLabel("Spiele", pageType, PAGE_TYPE_DAY_GAMES)} action={{name: "showPage", pageType: PAGE_TYPE_DAY_GAMES}}/>
                <MenuItem label={menuLabel("Players Ranking", pageType, PAGE_TYPE_PLAYER_RANKING)} action={{name: "showPage", pageType: PAGE_TYPE_PLAYER_RANKING}}/>
                <MenuItem label={menuLabel("Statistik: Saison", pageType, PAGE_TYPE_SEASON_STATS)} action={{name: "showPage", pageType: PAGE_TYPE_SEASON_STATS}}/>
                <MenuItem label={menuLabel("Statistik: Tag", pageType, PAGE_TYPE_DAY_STATS)} action={{name: "showPage", pageType: PAGE_TYPE_DAY_STATS}}/>
                <MenuItem label={menuLabel("Teams", pageType, PAGE_TYPE_TEAMS)} action={{name: "showPage", pageType: PAGE_TYPE_TEAMS}}/>
                <MenuItem label="... noch nich fertich" action={{name: "showPage", pageType: PAGE_TYPE_START}}/>
            </MenuItem>
            <MenuItem label="Spieltage" action={{name: "openSchedule"}}>
                {dayItems}
            </MenuItem>
        </Menu>
    );
}


class IblPage extends React.Component
{
    constructor(props) {
        super(props);
        this.onAction = this.onAction.bind(this);
        this.state = { seasons: {}, page: { 
                                        pageType: PAGE_TYPE_START, 
                                        season: null,
                                        dayIdx: 0
                                    } };
    }

    renderPage() {
        let { seasons, page } = this.state;

        switch (page.pageType) {
            case PAGE_TYPE_START:
                return <StartPage/>;
            case PAGE_TYPE_TEAM_RANKING:
                return <TeamRankingPage key={page.dayIdx} season={page.season} dayIdx={page.dayIdx}/>;
            case PAGE_TYPE_PLAYER_RANKING:
                return <PlayerRankingPage key={page.dayIdx} season={page.season} dayIdx={page.dayIdx}/>;
            case PAGE_TYPE_DAY_GAMES:
                return <GamesPage key={page.dayIdx} season={page.season} dayIdx={page.dayIdx}/>;
            case PAGE_TYPE_SEASON_STATS:
                return <SeasonStatisticsPage key={page.dayIdx} season={page.season} dayIdx={page.dayIdx}/>;
            case PAGE_TYPE_DAY_STATS:
                return <DayStatisticsPage key={page.dayIdx} season={page.season} dayIdx={page.dayIdx}/>;
            case PAGE_TYPE_TEAMS:
                return <TeamsPage key={page.dayIdx} season={page.season}/>;
            default:
                return <div>page type not yet supported: {page.pageType} </div>;
        }
    }

    render() {
        if ( ! this.state.page.season ) return null; // TODO
        return <ActionListener.Provider value={{onAction: this.onAction}}>
                   <IblMenu seasons={this.state.seasons} page={this.state.page}/>
                   <div className="pageContainer">
                       { this.renderPage() }
                   </div>
                </ActionListener.Provider>
    }

    componentDidMount() {
        
        this.loadSeason(SEASON_NAME_TODO);
    }

    onAction(action) {
        if ( ! action )
            return;
        switch ( action.name ) {
            case "showPage":
                let pageProps = {};
                if ( action.day || action.day===0 ) 
                    pageProps.dayIdx = action.day;
                if ( action.pageType ) 
                    pageProps.pageType = action.pageType;
                
                this.setState({...this.state, page: { ...this.state.page, ...pageProps}});
                break;
            default:
                console.error("Unhandled action: ", action);
        }
    }

    loadSeason(name) {
        ajax( GET, "/season/" + name )
            .done( (seasonData) => {
                const season = new Season(seasonData);
                let day = 0;
                while ( season.days[day+1] && season.days[day+1].done )
                    day++;

                this.setState( {
                         ...this.state,
                         seasons: {...this.state.seasons, [season.name]: season},
                         page: {...this.state.page, season: season, dayIdx: day}
                })
            })
            .fail( (p, e) => {
                console.error(`Error getting season '${name}'!  Problem: ${p}  Error: `, e);
            });
    }

}

export default IblPage;
