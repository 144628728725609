import React from 'react';

import { Util } from '../../shared/util';

import '../scss/page/DayPage.scss';

const localDateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };


class Game extends React.Component 
{
    constructor(props) {
        super(props);
        this.state = { visible: this.props.expanded };
    }

    render() {
        const game = this.props.game,
              tg1  = game.teamGame1,
              tg2  = game.teamGame2,
              ppg  = game.season.playersPerGame,
              // TODO let teamGame.lane strart from 0 in data files or remove teamGame.lane completely (assuming consecutive lanes)
              ln1  = game.season.firstLane-1 || 0; 
              
        let done = game.season.days[game.dayIdx].done;
        let exp  = done && (this.props.expanded || this.state.visible);

        let result1Class = "result" + ((done && tg1.result > tg2.result && ! tg1.blind) ? " winner" : "");  
        let result2Class = "result" + ((done && tg2.result > tg1.result && ! tg2.blind) ? " winner" : "");  

        const rows = [];
        rows.push( <div className="gameRow summary" key={game.gameIdx*100+0} >
                       <div className="name"> {tg1.team ? tg1.team.name : "???"} </div>
                       <div className={result1Class}> {done ? tg1.result : <span className="icon-road"><span>{tg1.lane + ln1}</span></span>} </div>
                       <div className="name2"> {tg2.team ? tg2.team.name : "???"} </div>
                       <div className={result2Class}> {done ? tg2.result : <span className="icon-road"><span>{tg2.lane + ln1}</span></span>} </div>
                   </div> );

        if ( exp ) {
            let totalHdc1 = 0;
            let totalHdc2 = 0;

            for ( let i = 0; i < ppg;  i++ ) {
                let player1 = tg1.players[i];
                let player2 = tg2.players[i];
                
                let p1hdc = player1.handicaps[game.dayIdx];
                let p2hdc = player2.handicaps[game.dayIdx];

                totalHdc1 += p1hdc;
                totalHdc2 += p2hdc;

                let name1Class = "name" + (player1.isBlind ? " blind" : "");  
                let name2Class = "name2" + (player2.isBlind ? " blind" : "");  

                rows.push( <div className="gameRow" key={game.gameIdx*100+i+1}>
                                <div className={name1Class}>{tg1.players[i].name || ""} </div>
                                <div className="result hdc">{p1hdc > 0 ? p1hdc + "+": ""}</div>
                                <div className="result">{tg1.results[i]}</div>
                                <div className={name2Class}>{tg2.players[i].name || ""} </div>
                                <div className="result hdc">{p2hdc > 0 ? p2hdc + "+": ""}</div>
                                <div className="result">{tg2.results[i]}</div>
                           </div> );
            }
            rows.push( <div className="gameRow score" key={game.gameIdx*100+23}>
                            <div className="name"></div>
                            <div className="result hdc">{totalHdc1 > 0 ? totalHdc1 + "+" : ""}</div>
                            <div className="result">{tg1.rawResult}</div>
                            <div className="name2"></div>
                            <div className="result hdc">{totalHdc2 > 0 ? totalHdc2 + "+" : ""}</div>
                            <div className="result">{tg2.rawResult}</div>
                       </div>
            );
            rows.push( <div className="gameRow score" key={game.gameIdx*100+24}>
                            <div className="name"></div>
                            <div className="result"></div>
                            <div className={result1Class}>{tg1.result}</div>
                            <div className="name2"></div>
                            <div className="result"></div>
                            <div className={result2Class}>{tg2.result}</div>
                       </div>                       
            );
        }

        const gameStyle = { height: 28*(exp ? ppg+3 : 1) }; 

                    // onClick={ () => { this.props.onClick(game) }}

        return <div className={"game" + (exp ? " expanded" : "")} 
                    style={gameStyle} 
                    // onTransitionEnd={ (e) => { this.onTransitionEnd(e) }}
                    onClick={ (e) => { this.onClick(e) }}
                >
                   {rows}
               </div>;
    }

    onTransitionEnd(event) {
        const target = event.target; 
        if ( target.classList.contains("game") && event.propertyName === "height" ) {
            // console.log("onTransitionEnd: " + event.propertyName + " -- ", event.target);
            this.setState({visible: false});
            return;
        }
        //  else 
        //     console.log("!GAME onTransitionEnd: ", event);
    }

    onClick(event) {
        if ( this.props.onClick )
        this.props.onClick(this.props.game);
        this.setState( () => {visible: this.state.visible || this.props.expanded} );
    }
}

class GamesPage extends React.Component 
{
    constructor(props)
    {
        super(props);

        this.state = {
            expandedRound: -1,
            expandedGame: -1,
        };
    }

    renderRound(round, roundIdx, dayNum) {
        // const games = round.games.map( (g, i) => this.renderGame(g, i, dayNum) );

        const expandedRound = this.state.expandedRound;
        const expandedGame  = this.state.expandedGame;

        const games = round.games.map( (g,i) => 
            <Game key={i} game={g}
                  expanded={roundIdx == expandedRound && i == expandedGame}
                  onClick={ (game) => this.onGameClicked(game) }/>
        );

        return (
            <div key={roundIdx} className="round">
                <div className="roundTitle">Runde {roundIdx+1}</div>
                <div className="roundTable">
                    {games}
                </div>
            </div>
        );
    }

    render() {
        const {season, dayIdx} = this.props;
        const day = season.days[dayIdx];

        const rounds = day.rounds.map( (r, i) => this.renderRound(r, i, dayIdx) );

        return (
            <div className="dayPage">
                <h4>Spiele {Util.dayLabel(day)}</h4>
                <h5>{day.done ? "Spiele antippen, um Details zu sehen" : "- Vorschau -" }</h5>
                <div className="roundsContainer">
                    {rounds}
                </div>
            </div>
        );
    }

    expand() {
        // this.setState( prevState => { expanded: ! prevState.expanded } ); 
        this.setState( prevState => { return {expanded: ! prevState.expanded}} ); 
    }
    
    onGameClicked(game) {
        // Console.log("Game clicked: " +);
        this.setState( prevState => {
            if ( prevState.expandedRound == game.roundIdx && prevState.expandedGame == game.gameIdx ) 
                return { expandedRound: -1, expandedGame: -1 };
            else
                return { expandedRound: game.roundIdx, expandedGame: game.gameIdx };
        }); 
    }

}

export { GamesPage };
