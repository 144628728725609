import React from 'react';

function StatisticTable(props) 
{
    let {title, values, nameTitle} = props;
    let pos = 0, lastScore = -1;

    let rows = values.map( (v, i) => {
        let posLabel = null;
        let score = v.score;
        if ( score != lastScore ) {
            posLabel = ++pos;
            lastScore = score;
        }

        return  <tr key={i}>
                    <td className="num">{posLabel}</td>
                    <td className="name">{v.name}</td>
                    {v.team ? <td className="team">{v.team.name}</td> : null}
                    {v.day  ? <td className="num" >{v.day.abbreviation}</td> : null} 
                    {values[0].hdc  ? 
                            <th className="twoCell num">
                                <span>
                                    {score}
                                </span>
                                <br/>
                                <span className="hdc">
                                    {v.hdc}
                                </span>
                            </th>
                        :
                            <th className="num">
                                    {score}
                            </th>
                    }
                </tr>
    });

    return <div className="statsContainer">
                <h5>{title}</h5>
                <div className="statsTableContainer">
                    <table>
                        <thead>
                            <tr>
                                <th className="num">#</th>
                                <th className="name">{nameTitle}</th>
                                {values[0].team ? <th className="team">Team</th> : null}
                                {values[0].day  ? <th className="num">Tag</th> : null}
                                {values[0].hdc  ? 
                                        <th className="num">
                                            <span>
                                                Score
                                            </span>
                                            <br/>
                                            <span className="hdc">
                                                ohne HDC
                                            </span>
                                        </th>
                                    :
                                        <th className="num">
                                            <span>
                                                Score
                                            </span>
                                        </th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                </div>
            </div>
}

export { StatisticTable };
