
const GET  = "GET";
const POST = "POST";

class Ajax {

    constructor(method, url, data, doneCb, failCb) 
    {
        this.url = url;
        this.doneCb = doneCb;
        this.failCb = failCb;

        // console.log("Ajaxing to: '" + url + "': ", data);

        const req = this.req = new XMLHttpRequest();

        req.addEventListener("load", this._onLoad.bind(this));
        req.addEventListener("error", this._onFail.bind(this, "error"));
        req.addEventListener("timeout", this._onFail.bind(this, "timeout"));
        req.addEventListener("abort", this._onFail.bind(this, "abort"));

        req.open(method, url);
        req.setRequestHeader("Content-Type", "application/json");
        req.send(JSON.stringify(data));

        // default delay
        this.delay(500);
    }

    delay(ms) {
        this._delayCallbackMs = ms;
        return this;
    }
    
    forceFail() {
        this._forceFail = true;
        return this;
    }

    done(cb) {
        this.doneCb = cb;
        return this;
    }

    fail(cb) {
        this.failCb = cb;
        return this;
    }

    _onLoad(e) {
    //    console.log( "this.req.responseText: " + this.req.responseText);
        
        if ( this.req.status < 200 || this.req.status >= 300 ) 
            return this._onFail("status " + this.req.status + " != 2xx !", e);
        if ( this._forceFail ) {
            this._onFail("forceFail", e);
        } else if ( this.doneCb ) {
            let json = this.req.responseText ? JSON.parse(this.req.responseText) : null;
            if ( this._delayCallbackMs )
                setTimeout(() => { this.doneCb(json) }, this._delayCallbackMs);
            else
                this.doneCb(json)
        }
    }

    _onFail(problem, e) {
        console.error(`Ajax request failed: \n    URL: ${this.url}\n    Problem: ${problem}\n   Event: `, e, "_delayCallbackMs: ", this._delayCallbackMs)
        if ( this.failCb ) {
            if ( this._delayCallbackMs )
                setTimeout(() => { this.failCb(problem, e) }, this._delayCallbackMs);
            else
                this.failCb(problem, e);
        }
    }
};



function ajax(method, url, data = {}, doneCb, failCb) {
    return new Ajax(method, url, data, doneCb, failCb);
};

export { GET, POST, ajax, Ajax };
